import React from 'react';
import { Helmet } from 'react-helmet';
import Contactbanner from '../components/Contact/Contactbanner';
import Contactsec1 from '../components/Contact/Contactsec1';
import ContactForm from '../components/Contact/ContactForm';

const Contact = () => {
  return (
   <>
   <Helmet>
      <title>Contact Us - Get in Touch with Quillnext</title>
      <meta name="description" content="Reach out to Quillnext for expert digital marketing and creative solutions. Contact us to discuss your project and how we can help you succeed." />
      <meta name="keywords" content="contact Quillnext, digital marketing agency contact, creative agency contact, get in touch, website development, SEO services" />
   </Helmet>
   <Contactbanner/>
   <Contactsec1/>
   <ContactForm/>
   </>
  );
};

export default Contact;
