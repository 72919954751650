import React from 'react'
import herosecimg from '../../assets/images/home/Homesec1.jpg'

export default function Homesec1() {
  return (
    <>
    <div className='container herosec-container '>
       <div className='row'>

       <div className='col-lg-6 '>
          <img src={herosecimg} alt='High-quality graphic design services by Quillnext'></img>
        </div>

        <div className='col-lg-6'>
            <h1>
            Crafting Innovation, Breathing Life into <span className='bluecolor'>Digital</span> Dreams, Together.
            </h1>

            <p>
                <i>We build your ideas with our creative skills</i><br/>
                
                Quillnext is a full-service digital marketing agency specializing in helping businesses elevate their online presence. With a team of experts in SEO, web design, social media, and branding, we have the knowledge and skills to enhance your company's visibility and engagement across various platforms. <br/>Whether you're looking to improve your website's performance, increase your search engine rankings, or develop a cohesive brand identity, Quillnext is here to help you reach your goals. Let us take your online presence to the next level and drive success for your business.
            </p>
            <hr/>
          <a className='btnbanner' href='/services'>Our Services</a>

        </div>

       </div>
    </div>
    </>
  )
}
