import React from 'react'

function Contactbanner() {
  return (
    <>
     <div className='contactbg text-center'>
        <h1>
        Start Your Project Now
        </h1>
    </div>
    
    </>
  )
}

export default Contactbanner