import React from 'react'

function Homesec4() {
  return (

    <>
    
    <div className='container text-center'>

    <h1>

    Innovate, Collaborate, Elevate:<br/>
    Your Journey to <span className='bluecolor'>Digital</span> Excellence Starts Here.
        </h1>

        <p>
        Our approach to digital success is anchored in innovation, collaboration, and elevation. We innovate by constantly exploring new ideas and technologies, pushing boundaries to deliver cutting-edge solutions. Collaboration is at the heart of what we do; we work closely with our clients to understand their unique needs and goals. Finally, we elevate, delivering results that exceed expectations and leave a lasting impact. With this approach, we’re committed to guiding you towards digital success.
        </p>

    </div>
    
    </>
  )
}

export default Homesec4