import React from 'react'

function Aboutsec1() {
  return (
    <>

<div className='aboutsec1bg '>
       <div className='container'>
                <div className='row'>
                    <div className='col-lg-7'>
                        <h1>
                        Mission: Empowering <span className='bluecolor'>Brands</span>,<br/> Igniting Success
                        </h1>
                        <p>At Quillnext, our mission is clear: to empower brands to thrive in the digital age. We believe that every brand has a unique story to tell, and we’re here to provide the platform and the tools to help them shine. With a focus on creativity, strategy, and collaboration, we’re committed to igniting success for our clients.</p>
                        <hr/>
                        <a className='btnbanner' href='/Contact'>Contact Us</a><br/><br/>

                    </div>
                </div>
       </div>
    </div>
    </>
  )
}

export default Aboutsec1