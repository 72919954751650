import React from 'react';
import Carousel from 'react-bootstrap/Carousel';
import Herobanner1 from '../../assets/images/home/homebanner1.jpg';
import Herobanner2 from '../../assets/images/home/homebanner2.jpg';
import Herobanner3 from '../../assets/images/home/homebanner3.jpg';

const Herobanner = () => {
  return (
   <>
   <Carousel className='herobanner'>
      <Carousel.Item>
        <img src={Herobanner1} alt='Hero banner showcasing digital marketing services and creative solutions by Quillnext'></img>
        <Carousel.Caption>
          <h1>Elevate your brand's online presence with our digital marketing services</h1>
          <p>Unlock the potential of your brand with our creative and innovative strategies that drive results.</p>
            <a className='btnbanner' href='/about'>Discover More</a><br/><br/>
        </Carousel.Caption>
      </Carousel.Item>
      <Carousel.Item>
      <img src={Herobanner2} alt='Hero banner showcasing digital marketing services and creative solutions by Quillnext'></img>

        <Carousel.Caption>
          <h1>Maximize your brand's potential with Quillnext's expert digital marketing services</h1>
          <p>From SEO and web design to social media and PPC, we'll help you elevate your online presence and drive results.</p>
          <a className='btnbanner' href='/about'>Discover More</a><br/><br/>

        </Carousel.Caption>
      </Carousel.Item>
      <Carousel.Item>
      <img src={Herobanner3} alt='Hero banner showcasing digital marketing services and creative solutions by Quillnext'></img>

        <Carousel.Caption>
          <h1>Unlock Your Brand's Potential with Quillnext Where Pixels Meet Possibilities</h1>
          <p>
          Elevate your brand's online presence with our expert SEO services, web design, social media management, and more.
          </p>
          <a className='btnbanner' href='/about'>Discover More</a><br/><br/>

        </Carousel.Caption>
      </Carousel.Item>
    </Carousel>

  
   
   </>
  );
}

export default Herobanner;