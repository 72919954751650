// src/useDarkMode.js
import { useEffect, useState } from 'react';

export function useDarkMode() {
  const [theme, setTheme] = useState('light');

  useEffect(() => {
    const prefersDarkScheme = window.matchMedia('(prefers-color-scheme: dark)');

    // Set the initial theme based on system preference or local storage
    const currentTheme = localStorage.getItem('theme') || (prefersDarkScheme.matches ? 'dark' : 'light');
    setTheme(currentTheme);
    document.body.classList.toggle('dark-mode', currentTheme === 'dark');

    const updateTheme = (e) => {
      const newTheme = e.matches ? 'dark' : 'light';
      setTheme(newTheme);
      document.body.classList.toggle('dark-mode', newTheme === 'dark');
      localStorage.setItem('theme', newTheme);
    };

    prefersDarkScheme.addEventListener('change', updateTheme);

    return () => prefersDarkScheme.removeEventListener('change', updateTheme);
  }, []);

  // Function to toggle the theme manually
  const toggleTheme = () => {
    const newTheme = theme === 'dark' ? 'light' : 'dark';
    setTheme(newTheme);
    document.body.classList.toggle('dark-mode', newTheme === 'dark');
    localStorage.setItem('theme', newTheme);
  };

  return [theme, toggleTheme];
}
