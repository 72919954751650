import React, { useState } from 'react';
import emailjs from 'emailjs-com';

const ContactForm = () => {
  const [formData, setFormData] = useState({
    name: '',
    email: '',
    phone: '',
    service: '',
    company: '',
    message: ''
  });

  const [formErrors, setFormErrors] = useState({});
  const [isSubmitting, setIsSubmitting] = useState(false);

  const validateForm = () => {
    const errors = {};
    const emailPattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    const phonePattern = /^[0-9]{10}$/;

    if (!formData.name) errors.name = 'Name is required';
    if (!formData.email) errors.email = 'Email is required';
    else if (!emailPattern.test(formData.email)) errors.email = 'Invalid email format';
    if (!formData.phone) errors.phone = 'Phone number is required';
    else if (!phonePattern.test(formData.phone)) errors.phone = 'Phone number should be 10 digits';
    if (!formData.service) errors.service = 'Please select a service';
    if (!formData.message) errors.message = 'Message is required';

    setFormErrors(errors);
    return Object.keys(errors).length === 0;
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    if (!validateForm()) return;

    setIsSubmitting(true);
    emailjs
      .send('service_d155092', 'template_uoo1n8n', formData, 'zTOY9jyNZQ4oo85Or')
      .then(
        (response) => {
          console.log('SUCCESS!', response.status, response.text);
          setIsSubmitting(false);
          alert('Your message has been sent!');
          setFormData({
            name: '',
            email: '',
            phone: '',
            service: '',
            company: '',
            message: ''
          });
        },
        (err) => {
          console.log('FAILED...', err);
          setIsSubmitting(false);
          alert('Failed to send the message. Please try again later.');
        }
      );
  };

  return (
    <form onSubmit={handleSubmit} className="contact-form container mb-4">
      <div className="form-group-inline">
        <div className="form-field">
          <label htmlFor="name">Name</label>
          <input type="text" id="name" name="name" value={formData.name} onChange={handleChange} />
          {formErrors.name && <p className="error">{formErrors.name}</p>}
        </div>
        <div className="form-field">
          <label htmlFor="email">Email</label>
          <input type="email" id="email" name="email" value={formData.email} onChange={handleChange} />
          {formErrors.email && <p className="error">{formErrors.email}</p>}
        </div>
      </div>
      
      <div className="form-group-inline">
        <div className="form-field">
          <label htmlFor="phone">Phone</label>
          <input type="text" id="phone" name="phone" value={formData.phone} onChange={handleChange} />
          {formErrors.phone && <p className="error">{formErrors.phone}</p>}
        </div>
        <div className="form-field">
          <label htmlFor="service">Services</label>
          <select id="service" name="service" value={formData.service} onChange={handleChange}>
            <option value="">Select a Service</option>
            <option value="Graphic Design">Graphic Design</option>
            <option value="Website/App Development">Website/App Development</option>
            <option value="Digital Marketing">Digital Marketing</option>
            <option value="Analytics & Reporting">Analytics & Reporting</option>
            <option value="Consultancy Services">Consultancy Services</option>
            <option value="Content Creation">Content Creation</option>
          </select>
          {formErrors.service && <p className="error">{formErrors.service}</p>}
        </div>
      </div>
      
      <div className="form-field">
        <label htmlFor="company">Company Name</label>
        <input type="text" id="company" name="company" value={formData.company} onChange={handleChange} />
      </div>
      
      <div className="form-field">
        <label htmlFor="message">Message</label>
        <textarea id="message" name="message" value={formData.message} onChange={handleChange} />
        {formErrors.message && <p className="error">{formErrors.message}</p>}
      </div>
      
      <button type="submit" disabled={isSubmitting}>
        {isSubmitting ? 'Sending...' : 'Submit'}
      </button>
    </form>
  );
};

export default ContactForm;
