import React from 'react';
import serviceicon1 from '../../assets/images/home/icon1.png';
import serviceicon2 from '../../assets/images/home/icon2.png';
import serviceicon3 from '../../assets/images/home/icon3.png';
import serviceicon4 from '../../assets/images/home/icon4.png';
import serviceicon5 from '../../assets/images/home/icon5.png';
import serviceicon6 from '../../assets/images/home/icon6.png';

function Homeservices() {
  return (
    <>
    <div className='container'>

        <h1 className='text-center'>
        Explore Our <span className='bluecolor'>Digital</span> Toolbox:<br/>
        Solutions Tailored for Your Success
        </h1>

        <div className='row'>

            <div className='col-lg-4 mt-4'>
                <div className='box'>
                    <img src={serviceicon1} alt='Creative graphic design services offered by Quillnext'></img>
                    
                    <h2 className='mt-3'>Graphic Design</h2>
                    <p>From Pixels to Brilliance: Transforming Ideas into Stunning Visuals</p>
                    <ul>
                        <li>Pixel-perfect designs</li>
                        <li>Engaging visual storytelling</li>
                        <li>Creative brand solutions</li>
                    </ul>
                </div>

            </div>

            <div className='col-lg-4 mt-4'>
                <div className='box'>
                    <img src={serviceicon2} alt='Professional website and app development by Quillnext'></img>

                    <h2 className='mt-3'>Website/App Development</h2>
                    <p>Building Tomorrow's Digital Landscapes: Seamless, Scalable, Sustainable.</p>
                    <ul>
                        <li>Seamless website solutions</li>
                        <li>Customized CRM systems</li>
                        <li>Scalable app development</li>
                    </ul>
                </div>

            </div>

            <div className='col-lg-4 mt-4'>
                <div className='box'>
                    <img src={serviceicon3} alt='Digital marketing strategies and campaigns by Quillnext'></img>

                    <h2 className='mt-3'>Digital Marketing</h2>
                    <p>Igniting Digital Growth: Engage, Convert, Amplify Your Brand's Reach.</p>
                     <ul>
                        <li>Targeted digital campaigns /PPC</li>
                        <li>Strategic social media management</li>
                        <li>ROI-driven marketing strategies</li>
                    </ul>
                </div>

            </div>

            <div className='col-lg-4 mt-4'>
                <div className='box'>
                    <img src={serviceicon4} alt='Analytics and reporting services by Quillnext'></img>

                    <h2 className='mt-3'>Analytics & Reporting</h2>
                    <p>Decoding Data, Driving Decisions: Insights that Shape Your Success.</p>
                     <ul>
                        <li>In-depth performance analysis</li>
                        <li>Actionable data insights</li>
                        <li>Customized reporting solutions</li>
                    </ul>
                </div>

            </div>

            <div className='col-lg-4 mt-4'>
                <div className='box'>
                    <img src={serviceicon5} alt='Expert consultancy services provided by Quillnext'></img>

                    <h2 className='mt-3'>Consultancy Services</h2>
                    <p>Guiding Your Journey: Data-Driven Insights, Actionable Strategies.</p>
                     <ul>
                        <li>Data-driven insights</li>
                        <li>Tailored business strategies</li>
                        <li>Actionable recommendations</li>
                    </ul>
                </div>

            </div>

            <div className='col-lg-4 mt-4'>
                <div className='box'>
                    <img src={serviceicon6} alt='Strategic SEO planning for improved search engine rankings by Quillnext'></img>

                    <h2 className='mt-3'>Content Creation</h2>
                    <p>Weaving Stories, Building Brands: Captivating Content for Every Platform.</p>
                     <ul>
                        <li>Compelling storytelling</li>
                        <li>SEO-friendly content</li>
                        <li>Multi Channel content distribution</li>
                    </ul>
                </div>

            </div>

        </div>
        <div className='text-center p-4'>
        <a className='btnbanner' href='/services'>View More</a>
        </div>

    </div>
    </>

  )
}

export default Homeservices