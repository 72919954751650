import React, { useState } from 'react';
import '../assets/css/Global.css'; // Import the CSS file
import 'bootstrap/dist/css/bootstrap.min.css';
import '../assets/css/Navbar.css'; // Import the CSS file
import '../../src/style.css';
import { FaFacebook, FaLinkedin, FaInstagram } from 'react-icons/fa';
import logoheader from '../assets/images/logo-header.png';


const Navbar = () => {
  const [isOpen, setIsOpen] = useState(false); // State to track if the menu is open

  const toggleMenu = () => {
    setIsOpen(!isOpen);
  };

  return (
    <nav className="navbar  p-4">
        {/* Logo on the left */}
      <div className="navbar-logo">
        <a href='/'><img src={logoheader} alt="Quillnext logo - Digital Marketing and Creative Agency" /></a> {/* Replace with your logo path */}
      </div>

      {/* Hamburger menu for mobile on the right */}
      <div className="hamburger" onClick={toggleMenu}>
        <div className="bar"></div>
        <div className="bar"></div>
        <div className="bar"></div>
      </div>

      {/* Menu for Desktop and Sidebar for Mobile */}
      <div className={`navbar-menu ${isOpen ? 'open' : ''}`}>
        {/* Close button inside the sidebar, visible only on mobile */}
        <div className="close-btn" onClick={toggleMenu}>
          &times;
        </div>
        <a href="/">HOME</a>
        <a href="/about">ABOUT</a>
        <a href="/services">SERVICES</a>
        <a href="/contact">CONTACT</a>

        {/* Social media links only visible in sidebar on mobile */}
        <div className="social-icons-mobile">
          <a href="https://www.facebook.com/quillnext" target="_blank" rel="noopener noreferrer"><FaFacebook /></a>
          <a href="https://www.linkedin.com/company/quillnext/" target="_blank" rel="noopener noreferrer"><FaLinkedin /></a>
          <a href="https://www.instagram.com/quill_next/" target="_blank" rel="noopener noreferrer"><FaInstagram /></a>
        </div>
      </div>

      {/* Social media icons and Call Back button for desktop only */}
      <div className="navbar-right">
        <div className="social-icons">
          <a href="https://www.facebook.com/quillnext" target="_blank" rel="noopener noreferrer"><FaFacebook /></a>
          <a href="https://www.linkedin.com/company/quillnext/" target="_blank" rel="noopener noreferrer"><FaLinkedin /></a>
          <a href="https://www.instagram.com/quill_next/" target="_blank" rel="noopener noreferrer"><FaInstagram /></a>
        </div>
        <a className="call-back" href='/contact'>Request A Call back</a>
      </div>
    </nav>
  );
};

export default Navbar;