import React from 'react'

function Servicesec1() {
  return (
    <>
    <div className='container'>
       <div className='row p-3'>
        <div className='col-lg-6'>
               
            </div>

            <div className='col-lg-6'>
                <p>
                Welcome to Quillnext, where we believe in a holistic approach to digital success. Our approach is built on three pillars: innovation, collaboration, and elevation. Through this framework, we empower brands to thrive in the ever-evolving digital landscape.</p>
                <hr/>
                <a className='btnbanner' href='/contact'>Contact Us</a><br/><br/>
            </div>

       </div>
    </div>
    </>
  )
}

export default Servicesec1