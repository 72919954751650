import React from 'react';
import { Helmet } from 'react-helmet';
import Herobanner from '../components/index/Herobanner';
import Homesec1 from '../components/index/Homesec1';
import Homeservices from '../components/index/Homeservices';
import Homesec4 from '../components/index/Homesec4';
import Contactpatch from '../components/Contactpatch';

const Home = () => {
  return (
   <>
   <Helmet>
      <title>Top Digital Marketing & Creative Agency - Quillnext</title>
      <meta name="description" content="Quillnext offers expert digital marketing, creative services, and website development. Discover how our innovative solutions can elevate your brand." />
      <meta name="keywords" content="digital marketing company, creative agency, top website development, advertising agency, SEO services, graphic design, digital marketing services, creative solutions" />
       {/* Open Graph Meta Tags */}
       <meta property="og:title" content="Quillnext - Digital Marketing and Creative Agency" />
        <meta property="og:description" content="Quillnext offers innovative digital marketing solutions, SEO services, and creative design for businesses to excel online." />
        <meta property="og:image" content="https://www.quillnext.com/static/media/logo-footer.983a0bd3db312a1f5e94.png" /> {/* Use the full image URL */}
        <meta property="og:url" content="https://www.quillnext.com" />
        <meta property="og:type" content="website" />
        <meta property="og:locale" content="en_US" />
   </Helmet>
   <Herobanner/>  
   <Homesec1/>
   <Homeservices/>
   <Homesec4/>
   <Contactpatch/>
   </>
  );
};

export default Home;
