import React from 'react'

function Whychooseus() {
  return (
    <>
    <div className='container'>
        <div className='row pt-4'>
           <div className='col-lg-6'>
                <h1>Why Choose <span className='bluecolor'>Quillnext</span> ?</h1>
                <p>At QuillNext, we’re more than just a digital agency – we’re your strategic partner in achieving online dominance. We combine award-winning creativity with data-driven strategies to craft impactful content, develop user-centric websites, and launch targeted marketing campaigns that drive real results. With a focus on building long-term partnerships, we’ll be there every step of the way, ensuring your brand thrives in the ever-evolving digital landscape.</p>
            </div>                    
           <div className='col-lg-6 p-2'>
                <div className='aboutbox p-4'>
                    <h5>01</h5>
                    <hr/>
                    <h2>Creative Excellence: Fueling Innovation, Elevating Brands</h2>
                    <p>We’re not satisfied with the status quo. Our team of designers, developers, and marketers is constantly pushing the boundaries of creativity to deliver exceptional results for our clients.</p>
                </div>

                <div className='aboutbox p-4 mt-4'>
                    <h5>02</h5>
                    <hr/>
                    <h2>Strategic Insight: Crafting Solutions, Tailored for You</h2>
                    <p>We don’t just create beautiful designs; we craft strategic solutions tailored to your brand’s unique goals and objectives. From branding to digital marketing, we approach every project with a strategic mindset.</p>
                </div>

                <div className='aboutbox p-4 mt-4'>
                    <h5>03</h5>
                    <hr/>
                    <h2>Collaborative Partnership: Your Success, Our Passion</h2>
                    <p>Your success is our success. We believe in building long-lasting partnerships with our clients, working closely with them every step of the way to ensure that their vision is brought to life in the most impactful way possible.</p>
                </div>
            </div>                    
        </div>
    </div>
    </>
  )
}

export default Whychooseus