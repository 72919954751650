import React from 'react'

function Aboutbanner() {
  return (
   <>
    <div className='aboutbg text-center'>
        <h1>
        Meet the Creative Minds Behind Quillnext:<br/>
        Crafting Innovation One Pixel at a Time
        </h1>
    </div>

   </>
  )
}

export default Aboutbanner