import React from 'react'

function Contactpatch() {
  return (
    <>

    <hr/>
    <div className='container text-center'>

        <h1>

        Ready to Elevate Your <span className='bluecolor'>Digital</span> Presence?
            </h1>

            <p>
            Reach Out and Let’s Make Magic Happen.
            </p>
            <a className='btnbanner' href='/Contact'>Contact Us</a><br/><br/>



    </div>

    
    
    
    
    </>
  )
}

export default Contactpatch