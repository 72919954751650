import React from 'react';
import { Helmet } from 'react-helmet';
import Servicebanner from '../components/Service/Servicebanner';
import Servicesec1 from '../components/Service/Servicesec1';
import Allservices from '../components/Service/Allservices';
import Aboutcontact from '../components/About/Aboutcontact';

const Services = () => {
  return (
   <>
   <Helmet>
      <title>Our Services - Digital Marketing, Website Development & More</title>
      <meta name="description" content="Explore Quillnext's range of services including graphic design, website/app development, digital marketing, analytics & reporting, and consultancy services." />
      <meta name="keywords" content="digital marketing services, graphic design, website development, app development, SEO, analytics, consultancy services, creative agency" />
   </Helmet>
   <Servicebanner/>
   <Servicesec1/>
   <Allservices/>
   <Aboutcontact/>
   
   </>
  );
};

export default Services;
