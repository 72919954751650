import React from 'react'
import serviceicon1 from '../../assets/images/home/icon1.png';
import serviceicon2 from '../../assets/images/home/icon2.png';
import serviceicon3 from '../../assets/images/home/icon3.png';
import serviceicon4 from '../../assets/images/home/icon4.png';
import serviceicon5 from '../../assets/images/home/icon5.png';
import serviceicon6 from '../../assets/images/home/icon6.png';

function Allservices() {
  return (

    <>
    <div className='container'> 
        <div className='text-center'>
            <h1>Innovate: Pushing Boundaries, Driving<br/> <span className='bluecolor'>Innovation</span></h1>
            <p>Innovation is at the heart of everything we do at Quillnext. We believe that true progress is achieved by pushing boundaries and exploring new possibilities. From cutting-edge designs to groundbreaking strategies, we’re constantly innovating to deliver solutions that exceed expectations and drive results.</p>
           
            <a className='btnbanner' href='/contact'>Start Your Project Now</a>
        </div>


        {/* All Services */}

       <div className='servicebox mt-5'>
            <div className='row allservices mt-5'>
                <div className='col-lg-6'>
                    <img className='' src={serviceicon1} alt='Creative graphic design process at Quillnext'></img>
                    <h2 className='mt-3'>Graphic Design:<br/>
                    We Bring Your Vision to Life</h2>
                    <p>Looking to elevate your brand with captivating visuals? At QuillNext, our team of award-winning graphic designers goes beyond pixels to craft stunning visuals that tell your unique story. We specialize in:</p>
                </div>
                <div className='col-lg-6'>
                    <ul>
                        <li>
                            <h6>Pixel-perfect designs:</h6>
                            <p>We meticulously craft every element, ensuring your visuals are not only aesthetically pleasing but also technically flawless across all digital platforms.</p>
                        </li>
                        <li>
                            <h6>Engaging visual storytelling</h6>
                            <p>Our designers are masters of weaving narratives through captivating imagery. We'll help you connect with your audience on a deeper level and leave a lasting impression.</p>
                        </li>
                        <li>
                            <h6>Creative brand solutions</h6>
                            <p>We don't create graphics in isolation. We take a holistic approach, ensuring your visuals seamlessly integrate with your overall brand strategy. From logo design and social media graphics to website layouts and infographics, we'll create a cohesive visual language that strengthens your brand recognition and resonates with your target audience.</p>
                        </li>
                    </ul>

                </div>
            </div>

       </div>


       <div className='servicebox mt-5'>
            <div className='row allservices mt-5'>
                <div className='col-lg-6'>
                    <img className='' src={serviceicon2} alt='Custom website development services by Quillnext, featuring modern and responsive designs'></img>
                    <h2 className='mt-3'>Website/App Development:<br/>Craft Your Perfect Website or App</h2>
                    <p>QuillNext is your one-stop shop for all your web development and application development needs. Our team of creative technologists specializes in crafting seamless, scalable, and sustainable digital experiences that propel your brand forward.</p>
                </div>
                <div className='col-lg-6'>
                    <ul>
                        <li>
                            <h6>Seamless website solutions</h6>
                            <p>We design and develop user-friendly websites that are not only visually stunning but also optimized for performance and conversions. Let us build a website that seamlessly integrates with your marketing efforts and becomes the cornerstone of your online presence.</p>
                        </li>
                        <li>
                            <h6>Customized CRM systems</h6>
                            <p>Streamline your customer relationship management with a custom CRM system tailored to your specific needs. Our team will develop a solution that empowers your sales and marketing teams to nurture leads, close deals, and foster stronger client relationships.</p>
                        </li>
                        <li>
                            <h6>Scalable app development</h6>
                            <p>Whether you need a mobile app to enhance customer engagement or a web app to streamline internal processes, our developers have the expertise to craft scalable solutions that can grow with your business.</p>
                        </li>
                    </ul>

                </div>
            </div>

       </div>

       <div className='servicebox mt-5'>
            <div className='row allservices mt-5'>
                <div className='col-lg-6'>
                    <img className='' src={serviceicon3} alt='Successful digital marketing campaigns executed by Quillnext to drive brand growth and engagement'></img>
                    <h2 className='mt-3'>Digital Marketing:<br/>Ignite Your Growth Engine</h2>
                    <p>Fuel long-term success with our data-driven digital marketing services. We craft targeted digital campaigns, including pay-per-click (PPC) advertising, to reach your ideal audience. Our expert social media management team will cultivate engaging online communities and amplify your brand's reach. We don't just generate clicks; we focus on delivering ROI-driven marketing strategies that convert leads into loyal customers.</p>
                </div>
                <div className='col-lg-6'>
                    <ul>
                        <li>
                            <h6>Targeted digital campaigns /PPC</h6>
                            <p>This emphasizes a data-driven approach to identify and engage your ideal customers across the web. We leverage sophisticated targeting options to ensure your message reaches the right people at the right time, maximizing your return on ad spend (ROAS).</p>
                        </li>
                        <li>
                            <h6>Strategic social media management</h6>
                            <p>This is a cornerstone of many digital marketing campaigns, allowing you to place targeted ads on search engines, social media platforms, and other websites. You only pay when someone clicks on your ad, making it a cost-effective way to drive qualified traffic to your website.</p>
                        </li>
                        <li>
                            <h6>ROI-driven marketing strategies</h6>
                            <p>This highlights the focus on achieving measurable results. We go beyond vanity metrics like likes and shares to track key performance indicators (KPIs) that align with your specific business goals. This might include website traffic, lead generation, conversion rates, or sales revenue. By constantly monitoring and analyzing data, we can optimize your campaigns in real-time to ensure they deliver the maximum return on investment (ROI).</p>
                        </li>
                    </ul>

                </div>
            </div>

       </div>


       <div className='servicebox mt-5'>
            <div className='row allservices mt-5'>
                <div className='col-lg-6'>
                    <img className='' src={serviceicon4} alt='Reporting tools and graphs used by Quillnext to track and analyze marketing campaign result'></img>
                    <h2 className='mt-3'>Analytics & Reporting:<br/>Unleash the Power of Your Digital Strategy</h2>
                    <p>Your data is a goldmine of insights, but unlocking its potential requires expert analysis. As a leading digital marketing agency, QuillNext provides comprehensive analytics and reporting solutions to empower your decision-making and fuel your digital success.</p>
                </div>
                <div className='col-lg-6'>
                    <ul>
                        <li>
                            <h6>In-depth performance analysis</h6>
                            <p>Our team of digital marketing specialists delves deep into your website traffic, user behavior, and campaign performance. We leverage industry-leading analytics tools to uncover hidden trends and opportunities for growth.</p>
                        </li>
                        <li>
                            <h6>Actionable data insights</h6>
                            <p>We go beyond just reporting numbers. We translate data into clear, actionable insights that guide your marketing strategy and optimize your campaigns for maximum impact.</p>
                        </li>
                        <li>
                            <h6>Customized reporting solutions</h6>
                            <p>We understand that one-size-fits-all reporting doesn't work. We tailor reports to your specific needs and goals, ensuring you have the information you need to make informed decisions quickly and efficiently.</p>
                        </li>
                    </ul>

                </div>
            </div>

       </div>


       <div className='servicebox mt-5'>
            <div className='row allservices mt-5'>
                <div className='col-lg-6'>
                    <img className='' src={serviceicon5} alt='Strategic planning and business consultancy services by Quillnext for effective growth strategies'></img>
                    <h2 className='mt-3'>Consultancy Services:<br/>Data-Driven Strategies for Growth</h2>
                    <p>Unlock the power of your digital potential with QuillNext's comprehensive consultancy services. We're a full-service digital agency specializing in crafting data-driven insights, actionable strategies, and tailored business solutions to propel your brand forward.</p>
                </div>
                <div className='col-lg-6'>
                    <ul>
                        <li>
                            <h6>Data-driven insights</h6>
                            <p>Our expert team leverages cutting-edge analytics to gain a deep understanding of your target audience, market trends, and competitor landscape. These insights become the foundation for informed decision-making across all digital marketing and development efforts.</p>
                        </li>
                        <li>
                            <h6>Tailored business strategies</h6>
                            <p>We don't just analyze data, we translate it into actionable strategies. Our team works closely with you to develop a customized roadmap for achieving your specific digital goals. Whether it's increasing website traffic, boosting conversions, or enhancing brand awareness, we'll craft a data-backed plan to get you there.</p>
                        </li>
                        <li>
                            <h6>Actionable recommendations</h6>
                            <p>A one-size-fits-all approach doesn't work in the digital world. We understand that every business has unique needs and objectives. Our consultants will take the time to understand your specific challenges and recommend solutions that are tailored to your industry and target audience.</p>
                        </li>
                    </ul>

                </div>
            </div>

       </div>


       <div className='servicebox mt-5'>
            <div className='row allservices mt-5'>
                <div className='col-lg-6'>
                    <img className='' src={serviceicon6} alt='Professional blog post writing services by Quillnext to enhance online presence'></img>
                    <h2 className='mt-3'>Content Creation:<br/>Your Brand's Digital Storytelling Powerhouse</h2>
                    <p>Craft Engaging Content. Drive Results. At QuillNext, our content creation experts are storytellers with a digital marketing edge. We weave captivating content that resonates with your audience across all platforms, establishing your brand as a thought leader and driving real results.</p>
                </div>
                <div className='col-lg-6'>
                    <ul>
                        <li>
                            <h6>Compelling storytelling</h6>
                            <p>We craft narratives that connect with your audience on an emotional level, fostering brand loyalty and trust.</p>
                        </li>
                        <li>
                            <h6>SEO-friendly content</h6>
                            <p>Our content is meticulously crafted to rank higher in search engine results pages (SERPs), ensuring your target audience finds you online.</p>
                        </li>
                        <li>
                            <h6>Multi Channel content distribution</h6>
                            <p>We don't just create content; we strategize its distribution across the most relevant digital channels, maximizing its reach and impact.</p>
                        </li>
                    </ul>

                </div>
            </div>

       </div>


        

    </div>
    </>

  )
}

export default Allservices