import React from 'react';
import { Helmet } from 'react-helmet';
import Aboutbanner from '../components/About/Aboutbanner';
import Ourstory from '../components/About/Ourstory';
import Aboutsec1 from '../components/About/Aboutsec1';
import Whychooseus from '../components/About/Whychooseus';
import Aboutcontact from '../components/About/Aboutcontact';

const About = () => {
  return (
   <>
   <Helmet>
      <title>About - Leading Digital Marketing & Creative Agency</title>
      <meta name="description" content="Learn about Quillnext's mission, values, and the team behind our success. We specialize in digital marketing, creative services, and website development." />
      <meta name="keywords" content="about Quillnext, digital marketing agency, creative agency, website development, SEO services, graphic design, digital marketing" />
   </Helmet>
    <Aboutbanner/>
    <Ourstory/>
    <Aboutsec1/>
    <Whychooseus/>
    <Aboutcontact/>
   </>
  );
};

export default About;
