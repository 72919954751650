import React from 'react'

function Aboutcontact() {
  return (
   <>
   <hr/>
    <div className='container text-center'>

        <h1>
        Join Us on Our Journey:<br/>
        Let's Create Something <span className='bluecolor'>Extraordinary</span>
            </h1>

            <p>
            Ready to take your brand to the next level? Join us on our journey and let’s create something extraordinary together. Contact us today to get started.
            </p>
            <a className='btnbanner' href='/Contact'>Contact Us</a><br/><br/>



    </div>
   </>
  )
}

export default Aboutcontact