import React from 'react'

function Ourstory() {
  return (
    <>
    <div className='container'>
       <div className='row p-5'>
        <div className='col-lg-6'>
                <h1>Our Story:</h1>
                <p><i>Crafting Digital Dreams</i></p>

            </div>

            <div className='col-lg-6'>
                <p>Embark on a journey through the evolution of Quillnext, from a spark of inspiration to a beacon of innovation in the digital realm. Founded on the principle that creativity knows no bounds, our story is one of passion, perseverance, and the relentless pursuit of excellence.</p>
                <hr/>
                <a className='btnbanner' href='/services'>Our Services</a><br/><br/>
            </div>

       </div>
    </div>
    </>
  )
}

export default Ourstory